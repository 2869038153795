import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

interface Props {
  readonly isActive?: boolean;
  readonly from?: number;
  readonly to?: number;
  readonly children: ReactNode;
}

const AnimationToggleScale: FC<Props> = ({
  isActive,
  from,
  to,
  children,
}: Props) => {
  const variants = {
    isNotActive: { scale: from },
    isActive: { scale: to },
  };

  return (
    <motion.div
      animate={isActive ? 'isActive' : 'isNotActive'}
      transition={{
        duration: 0.5,
        type: 'spring',
      }}
      style={{
        originX: 0.5,
        originY: 0.5,
      }}
      initial={{ scale: from }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

AnimationToggleScale.defaultProps = {
  isActive: false,
  from: 0,
  to: 1,
};

export default AnimationToggleScale;

import Link from 'next/link';
import React, { FC, MouseEventHandler, ReactNode } from 'react';
import Ripples from 'react-ripples';
import { LinkType } from '../../../models/link-type';
import styles from './button-secondary.module.scss';
import getTailwindConfig from '../../../helpers/get-tailwind-config';

interface Props extends Omit<LinkType, 'name'> {
  readonly name?: ReactNode;
  readonly buttonType?: 'button' | 'submit';
  readonly className?: string;
  readonly color?: string;
  readonly disabled?: boolean;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  readonly paddingX?: 'full' | 'half';
}

const ButtonSecondary: FC<Props> = ({
  buttonType,
  className,
  color,
  disabled,
  name,
  onClick,
  target,
  url,
  paddingX,
}: Props) => {
  const fullConfig = getTailwindConfig();
  const colorConfig = fullConfig.theme.colors[color];

  const borderColor =
    color === 'white' ? 'border-lemon-meringue' : `border-${color}`;
  const disabledColor =
    color === 'white'
      ? 'bg-grey-100 border-3 border-grey-100'
      : 'border-3 border-grey-200';
  const rippleColor =
    color === 'white'
      ? colorConfig.rgb
      : fullConfig.theme.colors['french-blue'].rgb;
  const textColor = color === 'white' ? 'text-white' : 'text-french-blue';

  return (
    <Ripples
      className={`${className} mx-auto md:mx-0 rounded-full w-full md:w-min`}
      color={disabled ? '' : `rgba(${rippleColor}, .3)`}
      during={1200}
    >
      {!url && (
        <button
          className={
            `${disabled ? disabledColor : borderColor} ` +
            `${disabled ? '' : 'hover:bg-french-blue-secondary-cta-hover '}` +
            `${disabled ? 'text-grey-600' : textColor} ` +
            `${disabled ? 'cursor-default' : 'cursor-pointer'} ` +
            `${paddingX === 'full' ? 'px-12' : 'px-6'} ` +
            `${styles.button}`
          }
          disabled={disabled}
          onClick={onClick}
          // eslint-disable-next-line react/button-has-type
          type={buttonType}
        >
          <span className="page-heading-six whitespace-nowrap z-10">
            {name}
          </span>
        </button>
      )}

      {url && (
        <Link href={url} passHref>
          <a
            className={
              `${disabled ? disabledColor : borderColor} ` +
              `${disabled ? '' : 'hover:bg-french-blue-secondary-cta-hover '}` +
              `${disabled ? 'text-grey-600' : textColor} ` +
              `${disabled ? 'cursor-default' : 'cursor-pointer'} ` +
              `${disabled ? 'pointer-events-none' : 'pointer-events-auto'} ` +
              `${paddingX === 'full' ? 'px-12' : 'px-6'} ` +
              `${styles.button}`
            }
            href="replace"
            onClick={onClick}
            target={target || '_self'}
          >
            <span className="page-heading-six whitespace-nowrap z-10">
              {name}
            </span>
          </a>
        </Link>
      )}
    </Ripples>
  );
};

ButtonSecondary.defaultProps = {
  name: null,
  buttonType: 'button',
  className: '',
  color: 'lemon-meringue',
  disabled: false,
  onClick: null,
  paddingX: 'full',
};

export default ButtonSecondary;

import React, { FC, MouseEvent, ReactNode, useContext } from 'react';
import IcomoonIconButton from '../../atoms/icomoon-icon-button/icomoon-icon-button';
import close from '../../providers/overlay/close.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';

interface Props {
  children: ReactNode;
  event: MouseEvent;
  position?: 'left' | 'right';
  size?: 'small' | 'large';
}

const Tooltip: FC<Props> = ({ children, event, position, size }: Props) => {
  const overlapDispatch = useContext(OverlayDispatchContext);
  const boundingRect = (event.target as HTMLElement).getBoundingClientRect();

  const positionLeftOffset = size === 'small' ? 320 : 512;

  return (
    <div
      className={`
        flex items-center h-screen md:h-auto md:absolute md:transform md:-translate-y-1/3 py-8 md:py-0 px-4 w-full
        ${size === 'small' && 'md:max-w-xs'}
        ${size === 'large' && 'md:max-w-lg'}
      `}
      style={{
        top: boundingRect.top + boundingRect.height / 2 - 8,
        left:
          position === 'right'
            ? boundingRect.right
            : boundingRect.left - positionLeftOffset,
      }}
    >
      <div className="flex bg-white shadow-lg rounded-lg w-full">
        <div
          className={`${
            position === 'right' ? '-translate-x-2' : 'right-0 -translate-x-2'
          } hidden md:block absolute inset-y-1/3 w-4 h-4 bg-white transform rotate-45`}
        />

        <div className="p-4 w-full">{children}</div>

        <div>
          <IcomoonIconButton
            className="p-2"
            color="grey-600"
            name="close"
            onClick={() => overlapDispatch(close())}
            size="sm"
          />
        </div>
      </div>
    </div>
  );
};

Tooltip.defaultProps = {
  position: 'right',
  size: 'small',
};

export default Tooltip;

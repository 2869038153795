import resolveConfig from 'tailwindcss/resolveConfig';

export default function getTailwindConfig(): ReturnType<resolveConfig> {
  return preval`
    const resolveConfig = require('tailwindcss/resolveConfig');
    const tailwindConfig = require('../../tailwind.config');

    module.exports = resolveConfig(tailwindConfig);
  `;
}

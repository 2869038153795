import Link from 'next/link';
import React, { FC, MouseEventHandler, ReactNode } from 'react';
import Ripples from 'react-ripples';
import { LinkType } from '../../../models/link-type';
import styles from './button-primary.module.scss';
import getTailwindConfig from '../../../helpers/get-tailwind-config';

interface Props extends Omit<LinkType, 'name'> {
  readonly name?: ReactNode;
  readonly buttonType?: 'button' | 'submit';
  readonly className?: string;
  readonly color?: string;
  readonly disabled?: boolean;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  readonly paddingX?: 'full' | 'half';
  readonly paddingY?: 'full' | 'half';
}

const ButtonPrimary: FC<Props> = ({
  buttonType,
  className,
  color,
  disabled,
  name,
  onClick,
  target,
  url,
  paddingX,
  paddingY,
}: Props) => {
  const fullConfig = getTailwindConfig();
  const colorConfig = fullConfig.theme.colors[color];

  const shadowColor =
    color === 'white' ? colorConfig['contrast-rgb'] : colorConfig.rgb;
  const disabledColor = color === 'white' ? 'bg-grey-100' : 'bg-grey-600';
  const disabledTextColor = color === 'white' ? 'text-grey-600' : 'text-white';
  const hoverColor =
    color === 'white' ? `hover:text-french-blue` : `hover:bg-${color}-light`;
  const rippleColor =
    color === 'white'
      ? fullConfig.theme.colors['french-blue'].rgb
      : colorConfig['dark-rgb'];

  return (
    <div
      className={`${className} mx-auto md:mx-0 rounded-full w-full md:w-min`}
      style={{
        boxShadow: disabled
          ? 'none'
          : `0 20px 25px -5px rgba(${shadowColor}, 0.15), 0 10px 10px -5px rgba(${shadowColor}, 0.05)`,
      }}
    >
      <Ripples
        className="rounded-full w-full"
        color={disabled ? '' : `rgba(${rippleColor}, .4)`}
        during={1200}
      >
        {!url && (
          <button
            className={
              `${disabled ? disabledColor : `bg-${color}`} ` +
              `${disabled ? '' : `${hoverColor} `}` +
              `${disabled ? disabledTextColor : `text-${color}-contrast`} ` +
              `${disabled ? 'cursor-default' : 'cursor-pointer'} ` +
              `${paddingX === 'full' ? 'px-12' : 'px-6'} ` +
              `${paddingY === 'half' ? 'py-3' : 'py-4'} ` +
              `${styles.button}`
            }
            disabled={disabled}
            onClick={onClick}
            // eslint-disable-next-line react/button-has-type
            type={buttonType}
          >
            <span className="page-heading-six whitespace-nowrap z-10">
              {name}
            </span>
          </button>
        )}

        {url && (
          <Link href={url} passHref>
            <a
              className={
                `${disabled ? disabledColor : `bg-${color}`} ` +
                `${disabled ? '' : `${hoverColor} `}` +
                `${disabled ? disabledTextColor : `text-${color}-contrast`} ` +
                `${disabled ? 'cursor-default' : 'cursor-pointer'} ` +
                `${disabled ? 'pointer-events-none' : 'pointer-events-auto'} ` +
                `${paddingX === 'full' ? 'px-12' : 'px-6'} ` +
                `${paddingY === 'half' ? 'py-3' : 'py-4'} ` +
                `${styles.button}`
              }
              href="replace"
              onClick={onClick}
              target={target || '_self'}
            >
              <span className="page-heading-six whitespace-nowrap z-10">
                {name}
              </span>
            </a>
          </Link>
        )}
      </Ripples>
    </div>
  );
};

ButtonPrimary.defaultProps = {
  name: null,
  buttonType: 'button',
  className: '',
  color: 'french-blue',
  disabled: false,
  onClick: null,
  paddingX: 'full',
  paddingY: 'full',
};

export default ButtonPrimary;

import React, { FC, ReactNode, useContext } from 'react';
import Tooltip from '../../molecules/tooltip/tooltip';
import open from '../../providers/overlay/open.overlay.actions';
import { OverlayDispatchContext } from '../../providers/overlay/overlay.provider';
import IcomoonIconButton from '../icomoon-icon-button/icomoon-icon-button';

interface Props {
  center?: boolean;
  children: ReactNode;
  error?: string;
  help?: string;
  label?: string;
  labelColor?: string;
  required?: boolean;
  textLabel?: string;
  tooltipComponent?: ReactNode;
  tooltipSize?: 'small' | 'large';
  containerClassName?: string;
  classNames?: string;
}

const FieldControl: FC<Props> = ({
  center,
  children,
  error,
  help,
  label,
  labelColor,
  required,
  textLabel,
  tooltipComponent,
  tooltipSize,
  containerClassName,
  classNames,
}: Props) => {
  const overlayDispatch = useContext(OverlayDispatchContext);

  const labelWrapper = (child: ReactNode) => {
    if (label) {
      return <label>{child}</label>;
    }

    return child;
  };

  const hasLabel = !!label || !!textLabel;

  return (
    <div
      className={`
    ${center ? 'flex flex-col items-center' : ''}
    ${containerClassName} 
    ${classNames && classNames}`}
    >
      {labelWrapper(
        <>
          <div className="flex items-center mb-1">
            {hasLabel && (
              <h6>
                <span
                  style={{ fontWeight: 400 }}
                  className={`
                    page-heading-six mr-2 
                    ${error ? 'text-invalid' : `text-${labelColor}`}
                  `}
                >
                  {textLabel || label}
                </span>

                {required && (
                  <span
                    className={` 
                      ${error ? 'text-invalid' : 'text-french-blue'}
                      -ml-1 
                    `}
                  >
                    *
                  </span>
                )}
              </h6>
            )}

            {tooltipComponent && (
              <div className={`${required ? 'ml-2' : ''} -mb-2px`}>
                <IcomoonIconButton
                  className="h-5 w-5"
                  color="french-blue"
                  name="question-circle"
                  onClick={(event) =>
                    overlayDispatch(
                      open(
                        <Tooltip event={event} size={tooltipSize}>
                          {tooltipComponent}
                        </Tooltip>
                      )
                    )
                  }
                  size="base"
                />
              </div>
            )}
          </div>

          <div>{children}</div>
        </>
      )}
      {help && <span className="block mt-1 text-sm text-grey-600">{help}</span>}
      {error && (
        <span className="block mt-1 text-sm text-invalid">{error}</span>
      )}
    </div>
  );
};

FieldControl.defaultProps = {
  center: false,
  error: null,
  help: null,
  label: null,
  labelColor: 'black',
  required: false,
  textLabel: null,
  tooltipComponent: null,
  tooltipSize: 'small',
  containerClassName: '',
  classNames: '',
};

export default FieldControl;

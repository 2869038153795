import { motion } from 'framer-motion';
import React, { FC } from 'react';

interface Props {
  readonly className?: string;
  readonly color?: string;
  readonly type?: string;
}

const LoadingSpinner: FC<Props> = ({ className, color, type }: Props) => (
  <div
    className={`${className} ${
      !type ? 'flex items-center justify-center w-full' : ''
    }`}
  >
    <motion.div
      animate="end"
      className="flex justify-around h-8 w-8"
      initial="start"
      variants={{
        start: {
          transition: {
            staggerChildren: 0.2,
          },
        },
        end: {
          transition: {
            staggerChildren: 0.2,
          },
        },
      }}
    >
      {new Array(3).fill(null).map((item: null, index: number) => (
        <motion.span
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={`block h-2 bg-${color} rounded-full w-2`}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            repeatType: 'mirror',
            ease: 'easeInOut',
          }}
          variants={{
            start: {
              y: '50%',
            },
            end: {
              y: '150%',
            },
          }}
        />
      ))}
    </motion.div>
  </div>
);

LoadingSpinner.defaultProps = {
  className: '',
  color: 'french-blue',
  type: '',
};

export default LoadingSpinner;

import Link from 'next/link';
import React, { FC, MouseEventHandler, ReactNode, useState } from 'react';
import { motion } from 'framer-motion';
import { LinkType } from '../../../models/link-type';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import LoadingSpinner from '../loading-spinner/loading-spinner';

interface Props extends Omit<LinkType, 'name'> {
  readonly name?: ReactNode | any;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly download?: boolean;
  readonly hideOverflow?: boolean;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement>;
  readonly showIcon?: boolean;
  readonly textSize?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl';
  readonly textColor?: string;
  readonly ariaLabel?: string;
  readonly loading?: boolean;
}

const TextLink: FC<Props> = ({
  className,
  disabled,
  download,
  hideOverflow,
  name,
  onClick,
  showIcon,
  target,
  textSize,
  textColor,
  url,
  ariaLabel,
  loading,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);

  const colorClassNames = disabled
    ? 'text-grey-600 pointer-events-none'
    : `text-${textColor} hover:text-french-blue-light transition-colors duration-400 ease-out`;
  const cursorClassNames = disabled ? 'cursor-default' : 'cursor-pointer';
  const labelSpacingClassNames = showIcon ? 'mr-2' : 'mr-0';

  const underlineVariants = {
    isHovered: { opacity: 1, width: '100%' },
    isNotHovered: { opacity: 0, width: 0 },
  };

  const chevronVariants = {
    isHovered: {
      x: [0, 3, 0, 3, 0],
    },
    isNotHovered: { x: 0 },
  };

  const LinkWrapper: FC = ({ children }) =>
    url && !download ? (
      <Link href={url}>{children}</Link>
    ) : (
      <div className="relative flex items-center">{children}</div>
    );

  return (
    <motion.div
      className={`${className} ${hideOverflow ? 'overflow-hidden' : ''}`}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <LinkWrapper>
        <a
          className={`${colorClassNames} ${cursorClassNames} ${labelSpacingClassNames} flex items-center relative`}
          href={url}
          onClick={onClick}
          target={target}
          download={download}
          aria-label={`${name} - ${ariaLabel}`}
        >
          <span
            className={
              `${labelSpacingClassNames} ` +
              `${hideOverflow ? 'truncate' : 'break-all'} ` +
              `text-${textSize} ` +
              'font-bold'
            }
          >
            {name}
          </span>

          {showIcon && (
            <motion.div
              animate={isHovered && !disabled ? 'isHovered' : 'isNotHovered'}
              className="-mb-1"
              initial="isNotHovered"
              transition={{ duration: 1 }}
              variants={chevronVariants}
            >
              <IcomoonIcon
                color={disabled ? 'text-grey-600' : 'french-blue'}
                name="chevron-right"
                size="md"
              />
            </motion.div>
          )}

          {typeof name === 'string' &&
            loading &&
            (name.toLowerCase().includes('logout') ||
              name.toUpperCase().includes('Logout')) && (
              <LoadingSpinner className="w-5 justify-item-start items-start absolute -right-16" />
            )}
        </a>
      </LinkWrapper>

      {!showIcon && !disabled && (
        <motion.div
          animate={isHovered ? 'isHovered' : 'isNotHovered'}
          className="border-t-2 border-french-blue-light -mt-2px"
          initial="isNotHovered"
          transition={{ duration: 0.4 }}
          variants={underlineVariants}
        />
      )}
    </motion.div>
  );
};

TextLink.defaultProps = {
  name: null,
  className: '',
  disabled: false,
  download: undefined,
  hideOverflow: false,
  onClick: null,
  showIcon: false,
  textSize: 'base',
  textColor: 'french-blue',
  ariaLabel: '',
  loading: false, // Add default value for loading prop
};

export default TextLink;

import React, { FC } from 'react';

interface Props {
  readonly className?: string;
  readonly color?: string;
  readonly name: string;
  readonly size?: string;
}

const IcomoonIcon: FC<Props> = ({ className, color, name, size }: Props) => (
  <span className={`${className} icon-${name} text-${color} text-${size}`} />
);

IcomoonIcon.defaultProps = {
  className: '',
  color: 'black',
  size: '2xl',
};

export default IcomoonIcon;

import { ReactNode } from 'react';
import Action from '../../../types/action';
import OverlayActions from './overlay.actions';

export default function open(
  component: ReactNode,
  keepNav?: boolean,
  isHeaderSearch?: boolean,
  lockScroll?: boolean,
  isAccount?: boolean,
  isMultiAdd?: boolean
): Action {
  return {
    type: OverlayActions.Open,
    payload: {
      component,
      isHeaderSearch,
      keepNav,
      lockScroll,
      isAccount,
      isMultiAdd,
    },
  };
}

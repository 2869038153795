import React, { FC, useState } from 'react';
import IcomoonIconButton from '../icomoon-icon-button/icomoon-icon-button';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';

interface Props {
  alwaysShowRing?: boolean;
  backgroundColor?: string;
  ringColor?: string;
  placeholderColor?: string;
  disabled?: boolean;
  error?: boolean;
  iconName?: string;
  iconPosition?: 'left' | 'right';
  max?: number;
  min?: number;
  onBlur?: () => void;
  onChange?: (value: string | number | null) => void;
  onFocus?: () => void;
  onIconClick?: () => void;
  paddingX?: 'full' | 'half';
  placeholder?: string;
  step?: number;
  textAlign?: 'left' | 'center';
  type?: string;
  value?: string | number | null;
  containerClassName?: string;
  inputClassName?: string;
  inputProps?: object;
}

const FieldText: FC<Props> = ({
  alwaysShowRing,
  backgroundColor,
  ringColor,
  placeholderColor,
  disabled,
  error,
  iconName,
  iconPosition,
  max,
  min,
  onBlur,
  onChange,
  onFocus,
  onIconClick,
  paddingX,
  placeholder,
  step,
  textAlign,
  type,
  value,
  containerClassName,
  inputClassName,
  inputProps,
}: Props) => {
  const [typeOverride, setTypeOverride] = useState(type);

  let textColor = '';
  let background = `bg-${backgroundColor}`;
  let ring = `ring-inset ring-${ringColor} focus:ring-1`;
  let iconColor = 'french-blue';
  let inputPaddingX = 'px-6';
  let inputTextAlign = 'text-left';
  const placeholderClass = `placeholder-${placeholderColor}`;

  if (alwaysShowRing) {
    ring = `ring-inset ring-${ringColor} ring-1 focus:ring-focus`;
  }

  if (disabled) {
    background = 'bg-grey-50';
    iconColor = 'grey-600';
    textColor = 'text-grey-600';
  }

  if (error) {
    background = 'bg-invalid-bg';
    ring = 'ring-inset ring-invalid ring-1';
    iconColor = 'invalid';
  }

  if (iconName && iconPosition === 'left') {
    inputPaddingX = 'pl-14 pr-6';
  }

  if (
    type === 'password' ||
    type === 'search' ||
    (iconName && iconPosition === 'right')
  ) {
    inputPaddingX = 'pl-6 pr-16';
  }

  if (paddingX === 'half') {
    inputPaddingX = 'px-3';
  }

  if (textAlign === 'center') {
    inputTextAlign = 'text-center';
  }

  return (
    <div className={`relative ${containerClassName}`}>
      <input
        type={typeOverride}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        className={`
          w-full rounded-md outline-none py-4 
          ${placeholderClass}
          ${textColor}
          ${ring}
          ${background}
          ${inputPaddingX}
          ${inputTextAlign}
          ${inputClassName}
        `}
        onBlur={onBlur}
        onChange={(event) => {
          onChange?.(event.target.value);
        }}
        onFocus={onFocus}
        max={max}
        min={min}
        step={step}
        {...inputProps}
      />

      {iconName && (
        <div
          className={
            `${iconPosition === 'right' ? 'right-4' : 'left-4'} ` +
            `${onIconClick ? '' : 'pointer-events-none'} ` +
            'absolute flex inset-y-0 items-center justify-center'
          }
        >
          {onIconClick && (
            <IcomoonIconButton
              name={iconName}
              size="base"
              color={iconColor}
              onClick={() => onIconClick?.()}
              className="w-10 h-10"
            />
          )}
          {!onIconClick && (
            <IcomoonIcon
              name={iconName}
              size="base"
              className="w-10 h-10 flex items-center justify-center"
              color={iconColor}
            />
          )}
        </div>
      )}

      {type === 'password' && (
        <div className="absolute flex inset-y-0 items-center right-4">
          <IcomoonIconButton
            onClick={(event) => {
              setTypeOverride(typeOverride === 'text' ? 'password' : 'text');
              event.preventDefault();
            }}
            name={typeOverride === 'text' ? 'eye-crossed' : 'eye'}
            size="lg"
            color={iconColor}
          />
        </div>
      )}

      {type === 'search' && !value && (
        <div className="absolute flex inset-y-0 items-center pointer-events-none right-6">
          <IcomoonIcon color={iconColor} name="search" size="base" />
        </div>
      )}

      {type === 'search' && value && (
        <div className="absolute flex inset-y-0 items-center right-3">
          <IcomoonIconButton
            color="grey-400"
            name="close-circle"
            onClick={() => {
              onIconClick();
              onChange?.('');
            }}
            size="lg"
          />
        </div>
      )}
    </div>
  );
};

FieldText.defaultProps = {
  alwaysShowRing: false,
  backgroundColor: 'light-blue',
  ringColor: 'focus',
  placeholderColor: 'grey-600',
  disabled: false,
  error: false,
  iconName: null,
  iconPosition: 'left',
  max: null,
  min: null,
  onChange: null,
  onIconClick: null,
  paddingX: 'full',
  placeholder: null,
  step: null,
  textAlign: 'left',
  type: 'text',
  value: null,
  onBlur: null,
  onFocus: null,
  containerClassName: '',
  inputClassName: '',
  inputProps: {},
};

export default FieldText;

import React, { FC, ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
  readonly className?: string;
}

const Layout: FC<Props> = ({ children, className }: Props) => (
  <section className={`${className} max-w-screen-2xl mx-auto px-4 md:px-15`}>
    {children}
  </section>
);

Layout.defaultProps = {
  className: '',
};

export default Layout;

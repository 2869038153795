import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
  readonly degrees?: number;
  readonly isRotated: boolean;
}

const AnimationToggleRotate: FC<Props> = ({
  children,
  degrees,
  isRotated,
}: Props) => {
  const variants = {
    isNotRotated: { rotate: 0 },
    isRotated: { rotate: degrees },
  };

  return (
    <motion.div
      animate={isRotated ? 'isRotated' : 'isNotRotated'}
      transition={{
        duration: 0.4,
        type: 'spring',
      }}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

AnimationToggleRotate.defaultProps = {
  degrees: 180,
};

export default AnimationToggleRotate;

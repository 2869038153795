import Link from 'next/link';
import React, { FC, FocusEventHandler, MouseEventHandler } from 'react';
import Ripples from 'react-ripples';
import IcomoonIcon from '../icomoon-icon/icomoon-icon';
import styles from './icomoon-icon-button.module.scss';
import getTailwindConfig from '../../../helpers/get-tailwind-config';

interface Props {
  readonly className?: string;
  readonly color?: string;
  readonly disabled?: boolean;
  readonly name: string;
  readonly onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  readonly onFocus?: FocusEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  readonly onMouseOver?: MouseEventHandler<
    HTMLAnchorElement | HTMLButtonElement
  >;
  readonly size?: string;
  readonly target?: string;
  readonly url?: string;
  readonly ariaLabel?: string;
}

const IcomoonIconButton: FC<Props> = ({
  className,
  color,
  disabled,
  name,
  onClick,
  onFocus,
  onMouseOver,
  size,
  target,
  url,
  ariaLabel,
}: Props) => {
  const fullConfig = getTailwindConfig();
  const colorConfig = fullConfig.theme.colors[color];
  const fontSize = fullConfig.theme.fontSize[size];

  return (
    <Ripples
      className="rounded-full"
      color={
        colorConfig?.rgb && !disabled ? `rgba(${colorConfig.rgb}, .3)` : ''
      }
      during={1200}
    >
      {!url && (
        <button
          className={
            `${className} ` +
            `${disabled ? 'cursor-default' : 'cursor-pointer'} ` +
            `${styles.button}`
          }
          disabled={disabled}
          onClick={onClick}
          onFocus={onFocus}
          onMouseOver={onMouseOver}
          type="button"
          style={{
            height: `calc(${fontSize} + 1.5rem)`,
            width: `calc(${fontSize} + 1.5rem)`,
          }}
          aria-label={name || 'button'}
        >
          <IcomoonIcon name={name} color={color} size={size} />
        </button>
      )}

      {url && (
        <Link href={url} passHref>
          <a
            className={
              `${className} ` +
              `${disabled ? 'cursor-default' : 'cursor-pointer'} ` +
              `${disabled ? 'pointer-events-none' : 'pointer-events-auto'} ` +
              `${styles.button}`
            }
            href="replace"
            onClick={onClick}
            onFocus={onFocus}
            onMouseOver={onMouseOver}
            style={{
              height: `calc(${fontSize} + 1.5rem)`,
              width: `calc(${fontSize} + 1.5rem)`,
            }}
            target={target}
            aria-label={ariaLabel && ariaLabel}
          >
            <IcomoonIcon name={name} color={color} size={size} />
          </a>
        </Link>
      )}
    </Ripples>
  );
};

IcomoonIconButton.defaultProps = {
  className: '',
  color: 'black',
  disabled: false,
  onClick: null,
  onFocus: null,
  onMouseOver: null,
  size: '2xl',
  target: '_self',
  url: '',
  ariaLabel: '',
};

export default IcomoonIconButton;
